import { render, staticRenderFns } from "./Edit.vue?vue&type=template&id=8038174a&scoped=true&"
import script from "./Edit.vue?vue&type=script&lang=ts&"
export * from "./Edit.vue?vue&type=script&lang=ts&"
import style0 from "./Edit.vue?vue&type=style&index=0&id=8038174a&lang=scss&scoped=true&"
import style1 from "./Edit.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8038174a",
  null
  
)

export default component.exports