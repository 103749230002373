














































































import Vue from 'vue'
import { ApiResponse, Model, Contact } from '@/interfaces'
import { mapState } from 'vuex'

export default Vue.extend({
  props: {
    type: {
      type: String,
      required: false,
      default: null,
    },
    parentId: {
      type: String,
      required: false,
      default: null,
    },
    structureType: {
      type: String,
      required: false,
      default: null,
    },
    activeTab: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      busy: false,
      loading: false,
      models: [] as Model[],
      chosenModel: null as number | null,
      photos: true,
      chosenEstimate: 3,
      contacts: [] as Contact[],
      selectedContactId: '',
      addons: 0,
    }
  },
  computed: {
    ...mapState(['user']),
  },
  watch: {
    type(newVal) {
      if (newVal) {
        this.getTemplates()
      }
    },
    structureType(newVal) {
      if (newVal && !this.activeTab) {
        this.getTemplates()
      }
    },
    parentId(newVal) {
      if (newVal && !this.activeTab) {
        this.getTemplates()
      }
    },
    activeTab(newVal) {
      if (newVal && newVal === 'documents') {
        this.getTemplates()
      }
    },
    user(newVal) {
      this.addons = newVal.client?.addons ?? 0
    },
  },
  mounted() {
    if (
      (this.activeTab && this.activeTab === 'documents') ||
      (!this.activeTab && (this.structureType || this.type || this.parentId))
    ) {
      this.getTemplates()
    }
    this.addons = this.user.client?.addons ?? 0
  },
  methods: {
    getTemplates() {
      this.$api
        .get(`/model/${this.type}/list`, {
          params: {
            structureType: this.structureType ?? null,
          },
        })
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.models = apiResponse.data
        })
    },
    getDocument() {
      this.busy = true

      const loading = this.$loading({
        target: '#container',
        text: 'Chargement des données...',
      })

      let data = {
        chosenModel: this.chosenModel ?? 0,
        images: 0,
        values: this.chosenEstimate ?? 0,
        selectedContactId: this.selectedContactId,
      }
      if (this.type === 'inventory' && this.photos) {
        data.images = 1
      }

      this.$api
        .post(`/document/${this.parentId}/${this.type}`, data)
        .then((response) => {
          const apiResponse = response.data as ApiResponse

          this.$emit('updateDocuments', apiResponse.data.documents)

          this.$notify({
            type: 'success',
            title: 'Succès',
            message: apiResponse.message ?? 'Opération réalisée avec succès',
          })
        })
        .catch((error) => {
          if (error.response) {
            const apiResponse = error.response.data as ApiResponse

            this.$notify({
              type: 'error',
              title: 'Erreur',
              message:
                apiResponse.message ?? 'Une erreur est survenue. Veuillez nous excuser pour la gêne occasionnée.',
            })
          }
        })
        .finally(() => {
          this.busy = false
          loading.close()
        })
    },
    loadContacts(isVisible: boolean) {
      if (isVisible) {
        this.contacts = []

        // TOTO : appel API liste des contact de la personne concernée de l'inventaire
        // this.parentId => Id de l'inventaire
        // this.selectedContactId => Id contact sélectionné

        this.$api.get('/inventory/' + this.parentId + '/contacts').then((response) => {
          const apiResponse = response.data as ApiResponse

          apiResponse.data.forEach((contact: Contact) => {
            this.contacts.push(contact)
          })
        })
      }
    },
  },
})
